import YapayIcon from 'assets/icons/YapayIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { TextContainer, ContentContainer, WelcomeContainer, WelcomeImage, WelcomeMessage, LogoContainer, StepMessage, WelcomeTitle, StyledLoadableButton, SpinnerContainer, ConsentInstructions } from './styles';
import OnboardingImage from 'assets/images/onboarding-app-tray.png'
import { useHistory } from 'react-router-dom';
import useWindowWidth from 'utils/useWindowWidth';
import RedirectToLogin from 'components/shared/RedirectToLogin';
import { useRef } from 'react';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { checkUserAllowence } from 'utils/checkUserAllowence';
import { CircularProgress } from '@material-ui/core';
import { GAMapRoutes } from 'utils/GAMapRoutes';
import { getDataStorage, setDataStorage } from 'utils/storage';
import { onboardingVariantsDictionary } from 'library/onboardingVariantsDictionary';
import { onboardingSectionsDictionary } from 'library/onboardingSectionsDictionary';
import { fetchSearch } from 'services/StoreKeys/POST/SearchService';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';
import { fetchPostTerms } from 'services/Signin/POST/PostTermsService';

export default function Welcome() {
  GAMapRoutes()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingButton, setLoadingButton] = useState(false)
  const [name, setName] = useState("")
  const [onboardingVariant, setOnboardingVariant] = useState("")

  const mounted = useRef(false);
  const history = useHistory()
  const windowWidth = useWindowWidth()

  const handleSubmit = async () => {
    setLoadingButton(true)
    const onboardingSectionIndex = Number(getDataStorage("onboarding_step") || 0)
    const profileSettingsStep = Number(getDataStorage("profile_settings_step") || 0)
    const mainSettingsStep = Number(getDataStorage("main_settings_step") || 0)
    const accountVerificationStep = Number(getDataStorage("account_verification_step") || 0)
    if (onboardingSectionIndex === 0 && profileSettingsStep === 0 && mainSettingsStep === 0 && accountVerificationStep === 0) {
      await fetchPostTerms()
    }
    setLoadingButton(false)
    history.push("/onboarding")
  }

  const fetchWelcomeData = useCallback(async () => {
    setLoading(true)
    await fetchSearch()
    const { name } = await fetchGetSigninUser()
    const { seller, verification_status } = await fetchGetAccountMyAccount()
    const hasVerifiedTag = verification_status.tag === "verified"
    if (mounted.current) {
      const isAVariant = !seller && hasVerifiedTag && verification_status.level === 5
      const isBVariant = !seller && !hasVerifiedTag && (verification_status.level === null || verification_status.level === 1)
      const isCVariant = seller && hasVerifiedTag && verification_status.level === 5
      const isDVariant = seller && !hasVerifiedTag && (verification_status.level === null || verification_status.level === 1)
      if (isAVariant) {
        setOnboardingVariant("onboardingAVariant")
        setDataStorage("onboardingVariant", "onboardingAVariant")
      } else if (isBVariant) {
        setOnboardingVariant("onboardingBVariant")
        setDataStorage("onboardingVariant", "onboardingBVariant")
      } else if (isCVariant) {
        setOnboardingVariant("onboardingCVariant")
        setDataStorage("onboardingVariant", "onboardingCVariant")
      } else if (isDVariant) {
        setOnboardingVariant("onboardingDVariant")
        setDataStorage("onboardingVariant", "onboardingDVariant")
      } else {
        setOnboardingVariant("onboardingDVariant")
        setDataStorage("onboardingVariant", "onboardingDVariant")
      }
      setName(name)
    }
    setLoading(false)
  }, [])

  const fetchUserAllowance = useCallback(async () => {
    const allowence = await checkUserAllowence()
    if (mounted.current) {
      if (!allowence) {
        history.replace("/dashboard")
      }
    }
  }, [history])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    fetchWelcomeData()
  }, [fetchWelcomeData])

  useEffect(() => {
    fetchUserAllowance()
  }, [fetchUserAllowance])

  return <>
    {
      isLoading ?
        <SpinnerContainer>
          <CircularProgress size={64} />
        </SpinnerContainer> :
        <>
          <WelcomeContainer>
            {
              windowWidth &&
              <LogoContainer>
                <YapayIcon size={windowWidth < 768 ? 0.678 : 1.26} />
              </LogoContainer>
            }
            <ContentContainer>
              <TextContainer>
                <WelcomeTitle>
                  {`Olá ${name}`}
                </WelcomeTitle>
                <WelcomeMessage>
                  Antes de acessar suas transações, vamos passar rapidamente pelas seguintes etapas:
                </WelcomeMessage>
                {
                  onboardingVariantsDictionary?.[onboardingVariant]?.sections.map((e, i) => {
                    return (
                      <StepMessage
                        key={i}
                      >
                        - {onboardingSectionsDictionary[e].welcomingTitle}
                      </StepMessage>
                    )
                  })
                }
                <ConsentInstructions>
                  {`Ao continuar, você concorda com o `}
                  <a
                    href="https://pagamentos.vindi.com.br/assets/contratos/contrato-yapay-2021-v1.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`termo de utilização dos serviços Vindi`}
                  </a>
                </ConsentInstructions>
              </TextContainer>
              <WelcomeImage src={OnboardingImage} />
            </ContentContainer>
            <StyledLoadableButton
              onClick={handleSubmit}
              id={"tela_inicial_onboarding_botao_ok"}
              isLoading={isLoadingButton}
              disabled={isLoadingButton}
            >
              Ok, vamos lá
            </StyledLoadableButton>
          </WelcomeContainer>
          <RedirectToLogin />
        </>
    }
  </>
}